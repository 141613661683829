import Modal from '../../shared/ui/Modal';
import { useCreateModalState } from '../../shared/hooks/modal';
import styles from './index.module.scss';
import Button from '../../shared/ui/Button';
import { useEffect, useState } from 'react';
import { getDayMothYearDateFormat } from '../../shared/utils/date';
import { useNavigate } from 'react-router-dom';
import { useAppNavigation } from '../../shared/hooks/navigation';
import { APP__ROUTS } from '../../shared/consts';
import palette from './assets/palette.png';
import easel from './assets/easel.png';
import mobileeasel from './assets/mobileeasel.png';

import clsx from 'clsx';

interface IContestBanner {
    isAlwaysActive?: boolean;
}

const ContestBanner: React.FC<IContestBanner> = ({ isAlwaysActive = false }) => {
    const { redirectToVote } = useAppNavigation();

    const [bannerStatus, setBannerStatus] = useState(isAlwaysActive);
    const navigate = useNavigate();
    const redirectToContest = () => {
        navigate(APP__ROUTS.contests + '/' + APP__ROUTS.contetSubmitForm + '?contest=16');
        setBannerStatus(false);
    };

    useEffect(() => {
        const viewBanner = setTimeout(() => {
            const popUpData = localStorage.getItem('podvig-popup-data');
            if (popUpData === null) {
                const currentDate = getDayMothYearDateFormat(new Date().toISOString());
                const popUpData = {
                    [currentDate]: true,
                };
                localStorage.setItem('podvig-popup-data', JSON.stringify(popUpData));
                setBannerStatus(true);
            }
            if (popUpData) {
                const popUpDataHashMap = JSON.parse(popUpData);
                const currentDate = getDayMothYearDateFormat(new Date().toISOString());
                if (popUpDataHashMap[currentDate] === undefined) {
                    const newData = { ...popUpDataHashMap, [currentDate]: true };
                    localStorage.setItem('podvig-popup-data', JSON.stringify(newData));
                    setBannerStatus(true);
                }
            }
        }, 15000);
        return () => {
            clearTimeout(viewBanner);
        };
    }, []);
    return (
        <>
            <Modal
                isOpen={bannerStatus}
                modalClassName={styles['banner__modal']}
                setStatus={() => {
                    setBannerStatus(false);
                }}
                additionalElements={
                    <>
                        <img className={styles.banner__easel} src={easel} alt="мольберт" />{' '}
                        <img className={styles.banner__palette} src={palette} alt="палитра" />
                    </>
                }
                className={styles.modal}
            >
                <article className={styles['banner']}>
                    <h2 className={styles['banner__title']}>
                        <span>
                            КОНКУРС ИЛЛЮСТРАЦИЙ
                            <br /> «ХУДОЖНИК: ОДА ПОДВИГУ»
                            <br /> Главный приз <span className={styles.text_hide}>—</span>{' '}
                        </span>

                        <span className={styles.banner__title__detail}>50 тысяч рублей!</span>
                    </h2>
                    <span className={clsx(styles['banner__description'], styles.text_hide)}>
                        Создай иллюстрацию к рассказу о герое и получи <strong>50 000</strong> РУБЛЕЙ! За 2 и 3 место
                        дарим электронные сертификаты на <strong>5000</strong> и <strong>10 000</strong> рублей.
                    </span>
                    <div className={styles['banner__contest_details']}>
                        <h3 className={styles.banner__contest_details_title}>
                            Успей подать заявку
                        </h3>
                        <h4 className={styles.banner__contest_details_subtitle}>
                            с 25 марта по 21 апреля.
                        </h4>
                        <div className={styles.text_hide}>
                            <h5 className={styles.banner__contest_details_title}>
                                Стань тем, кто покажет стране подвиги героев во всех красках!
                            </h5>
                        </div>
                    </div>
                    <button className={styles.btn} onClick={redirectToContest}>
                        Участвовать
                    </button>
                    <img className={styles.banner__mobileeasel} src={mobileeasel} alt="мольберт" />{' '}
                </article>
            </Modal>
        </>
    );
};

export default ContestBanner;
